







































































































































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import useAbstract from "@/use/abstract";

export default defineComponent({
  components: {
    MAbstractsOptions: () =>
      import("@/components/molecules/abstracts/m-abstracts-options.vue"),
    MSingleReviewersAssign: () =>
      import("@/components/molecules/abstracts/m-single-reviewers-assign.vue"),
    MAbstractDetails: () =>
      import("@/components/molecules/abstracts/m-abstract-details.vue"),
    MAbstractEdit: () =>
      import("@/components/molecules/abstracts/m-abstract-edit.vue"),
    MAbstractAccept: () =>
      import("@/components/molecules/abstracts/m-abstract-accept.vue"),
    MAbstractHistory: () =>
      import("@/components/molecules/abstracts/m-abstract-history.vue"),
  },

  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    author: {
      type: String,
      required: false,
      default: "",
    },
    status: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root }) {
    const { getTypeName, getStatusName } = useAbstract({ root });

    const state = reactive({
      singleSelect: false,
      expanded: [],
      selected: [],
      headers: [
        {
          value: "index",
          text: root.$tc("layout.misc.orderNumber"),
          width: 1,
          sortable: false,
        },
        {
          value: "title",
          text: root.$tc("panel.event.abstract.abstractList.list.title"),
        },
        {
          value: "status",
          text: root.$tc("panel.event.abstract.abstractList.list.status"),
          sortable: false,
          width: 1,
          align: "right",
        },
        {
          value: "sessionTopic",
          text: root.$tc("panel.event.abstract.abstractList.list.sessionTopic"),
          align: "right",
          width: "100px",
        },
        {
          value: "participant",
          text: root.$tc("panel.event.abstract.abstractList.list.sender"),
          align: "right",
          width: "100px",
        },
        {
          value: "authors",
          text: root.$tc("panel.event.abstract.abstractList.list.authors"),
          align: "right",
          width: 1,
        },
        {
          value: "type",
          text: root.$tc("panel.event.abstract.abstractList.list.type"),
          align: "right",
          width: "150px",
        },
        {
          value: "createdAt",
          text: root.$tc("panel.event.abstract.abstractList.list.addDate"),
          align: "center",
          width: "120px",
        },
        {
          value: "average",
          text: root.$tc("panel.event.abstract.abstractList.list.average"),
          align: "right",
          width: 1,
          sortable: false,
        },
        { value: "languageVersion", text: "", align: "right", width: 1 },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "right",
          width: 1,
        },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      success: false,
      error: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      title: "",
      titleTimeout: undefined as any,
      author: "",
      authorTimeout: undefined as any,
      reviewedDialog: {},
      editDialog: {},
      acceptDialog: false,
      acceptItem: {},
      acceptIndex: -1,
      historyDialog: false,
      historyItem: {},
      showDeleted: false,
      showUnassigned: false,
      showEdited: false,
    });

    const acceptDialog = (item: any) => {
      state.acceptItem = Object.assign({}, item);
      state.acceptDialog = true;
    };

    const historyDialog = (item: any) => {
      state.historyItem = Object.assign({}, item);
      state.historyDialog = true;
    };

    // Start fetch abstracts

    const fetchData = (isActive = true) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/abstract`, {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            title: state.title || undefined,
            author: state.author || undefined,
            status: props.status || undefined,
            type: props.type || undefined,
            showUnassigned: state.showUnassigned,
            showEdited: state.showEdited,
          },
        })
        .then(({ data: { abstracts, total } }) => {
          state.empty = false;
          state.items = abstracts.filter((el: any) => el.isActive === isActive);
          state.total = total;
          state.selected = [];
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    // End fetch abstracts

    watch(
      () => props.title,
      (newSearch) => {
        state.title = newSearch;
        clearTimeout(state.titleTimeout);
        state.titleTimeout = setTimeout(fetchData, 500);
      }
    );
    watch(
      () => props.author,
      (newSearch) => {
        state.author = newSearch;
        clearTimeout(state.authorTimeout);
        state.authorTimeout = setTimeout(fetchData, 500);
      }
    );

    onMounted(fetchData);

    watch(
      () => state.options,
      () => fetchData(!state.showDeleted),
      { deep: true }
    );
    watch(
      () => props.status,
      () => fetchData(!state.showDeleted)
    );
    watch(
      () => props.type,
      () => fetchData(!state.showDeleted)
    );

    watch(
      () => state.showDeleted,
      () => {
        if (state.showDeleted) {
          fetchData(false);
        } else fetchData(true);
      }
    );
    watch(
      () => state.showUnassigned,
      () => fetchData(!state.showDeleted)
    );
    watch(
      () => state.showEdited,
      () => fetchData(!state.showDeleted)
    );

    const changeStatusOrType = (
      status: string,
      id: string,
      type: string,
      isActive: boolean
    ) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        abstracts: [id],
        type: type,
        status: status,
        isActive,
      };

      state.loading = true;

      axiosInstance
        .put(`event/${root.$route.params.id}/abstract/bulk`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          fetchData(!state.showDeleted);
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    return {
      state,
      acceptDialog,
      historyDialog,
      fetchData,
      changeStatusOrType,
      getTypeName,
      getStatusName,
    };
  },
});
